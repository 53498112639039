.insetBox {
  box-shadow: inset 0 0 8px -3px #777;
}
.bankBalanceBox {
  height: 35vh;
  background: #f5f5f5;
  margin-bottom: 2vh;
  width: 100%;
  border: solid 2px #dcdcdc;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.houseBox {
  background: #fff;
  height: 25vh;
  margin-top: 1vh;
  border: solid 2px #dcdcdc;
  display: flex;
  justify-content: center;
}
.houseBox img {
  align-self: center;
  opacity: 0.4;
  max-width: 50vw;
}
.orderListTable {
  border-top: solid 2px #dcdcdc;
  background: #f5f5f5;
  height: 27vh;
  font-size: 1.7vh;
  overflow-y: auto;
}
.orderListTable thead {
  color: #808080;
  background: #f3f3f3;
}
.orderListTable thead th {
  padding: 0.6vh;
}
.orderListTable tbody td {
  padding: 0.6vh;
  color: #505050;
}
.orderListTable tbody td:first-of-type {
  padding: 0;
  width: 2.8vh;
}
.orderListTable tbody td .square {
  width: 1.8vh;
  height: 1.8vh;
  margin: 0.8vh;
  background: #5490c0;
}
.orderListHeader {
  background: #5490c0;
  padding: 1.1vh 0.8vh;
  height: 5vh;
  color: #fff;
}
.orderListHeader img {
  width: 2.5vh;
  margin-top: -0.8vh;
}
.orderListHeader span {
  margin-left: 1vh;
  font-size: 2vh;
}
