.icons-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.icons-group {
  margin: 0 10px;
  text-align: center;
}

.icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: inline-block;
  margin: 2px;
}

.icon.green {
  background-color: #4caf50;
}

.icon.amber {
  background-color: #ffeb3b;
}

.icon.red {
  background-color: #f44336;
}