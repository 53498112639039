.gistContainer {
  position: absolute;
  top: -7px;
  overflow: scroll;
  width: 500px;
  max-height: 550px;
  right: 295px;
}

@media (max-width: 850px) {
  .gistContainer {
    display: none;
  }
}
