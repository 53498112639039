.videoBox {
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 16px;
    border: 10px solid #5490c0;
}

.videoBox h3 {
    color: #5490c0;
    text-decoration: underline #5490c0;
}

.videoBox a {
    color: black;
}

.videoBox a:hover {
    font-weight: bold;
    text-decoration: none;
}