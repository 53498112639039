.insetBox {
  box-shadow: inset 0 0 8px -3px #777;
}
.background_md {
  background: #806ba9;
}
.background_md .timelineItemContent h5,
.background_md .timelineItemContent h4,
.background_md .timelineItemContent p {
  color: #fff;
}
.background_main {
  background: #331B3F;
}
.background_main .timelineItemContent h5,
.background_main .timelineItemContent h4,
.background_main .timelineItemContent p {
  color: #fff;
}
.timeline {
  padding: 0 !important;
  border-left: solid 1px #dcdcdc;
  height: 100%;
  background: #f8f8f8;
}
.timelineHead {
  color: #555;
  width: 100%;
  padding: 0.8vh 0 0 15px;
  border-bottom: solid 1px #dcdcdc;
  white-space: nowrap;
  height: 42px;
}
.timelineHead h4 {
  font-size: 1.6vh;
  font-weight: bold;
}
.timelineBody {
  overflow: auto;
  position: relative;
  height: calc(100% - 42px);
}
.timelineItem {
  display: flex;
  padding-left: 1vw;
  padding-right: 1vw;
}
.timelineItem:last-child .timelineItemContent {
  border-width: 2px;
  border-image: linear-gradient(to bottom, #cacfcf 2vh, transparent 2vh 100%) 0 100%;
}
.timelineItemContent {
  /* padding: 0.7vh 1.2vw;
  position: relative;
  flex: 1;
  flex-direction: column;
  color: rgba(0,0,0,0.7);
  border-left: 2px solid #cacfcf; */
}
.timelineItemContent:after {
  /* width: 2vh;
  height: 2vh;
  display: block;
  top: 1vh;
  position: absolute;
  left: calc(-1vh - 1px);
  border-radius: 2vh;
  content: '';
  border: 2px solid #cacfcf;
  background: #dadfdf; */
}
.timelineItemContent h5 {
  color: #999;
  font-size: 1.6vh;
}
.timelineItemContent h4 {
  color: #777;
  font-size: 1.8vh;
  font-weight: bold;
}
.timelineItemContent p {
  color: #888;
  font-size: 1em;
}
