.insetBox {
  box-shadow: inset 0 0 8px -3px #777;
}
.topBar {
  background: #fdfdfd;
  height: 5vh;
  margin: 0 !important;
  border-bottom: solid 1px #dcdcdc;
  width: 100%;
}

.burger {
  width: 5%;
  float: left;
}

.burgerPlus h5 {
  margin-top: 0.4vh;
  font-size: 3vh;
  color: #5490c0;
}
.burgerPlus.sales h5 {
  color: #cd8113;
}
.burgerPlus.production h5 {
  color: #4a8a72;
}
.burgerPlus.md h5 {
  color: #806ba9;
}
.burgerPlus.finance h5 {
  color: #c0081f;
}
.burgerPlus.marketing h5 {
  color: #0084d3;
}
.burgerImg {
  margin: 0.3vh 0 0 0.6vh;
  opacity: 0.5;
  font-size: 3vh;
}
.burger:hover {
  cursor: pointer;
}
.burger:hover:hover .burgerImg {
  opacity: 1;
}
.loginInfo {
  float: right;
  height: 100%;
  width: 40%;
}
.loginInfo .md {
  color: #806ba9;
}
.loginInfo .marketing {
  color: #0084d3;
}
.loginInfo .sales {
  color: #cd8113;
}
.loginInfo .production {
  color: #4a8a72;
}
.loginInfo .finance {
  color: #c0081f;
}
.loginInfo .facilitator {
  color: #5490c0;
}
.userIcon {
  float: left;
  padding-top: 0.2vh;
  font-size: 2.9vh;
}
.loggedInUser {
  float: left;
  width: 30%;
  padding: 2px;
  padding-right: 4px;
  border-right: solid 1px #dcdcdc;
  text-align: center;
}
.loggedInUser p {
  margin: 0;
  font-size: 1.2vh;
}
.loggedInUser h4 {
  margin-top: 10px;
  font-size: 1.4vh;
}
#logout, #Time {
  float: left;

}
#logout {
  width: 20%;
  text-align: center;
}
#Time {
  width: 50%;
  text-align: center;
  padding-top: 12px;
}
#logout h3 {
  margin-top: 10px;
  font-size: 1.4vh;
}
#logout:hover {
  color: #1b2935;
  cursor: pointer;
}
.screenshot {
  float: left;
  width: 25%;
}
.centerLogo {
  float: left;
  height: 100%;
  width: 55%;
  text-align: center;
  vertical-align: middle;
}

.centerLogo img {
  max-height: 100%;
  max-width: 100%;
  vertical-align: top;
  float: left;
}

.centerLogo h3 {
  float: left;
}
.MessageHolder {
  height: 100%;
  width: 30%;
  float: left;
  color: #5490c0;
  font-weight: bold;
}

@media (max-height: 780px) {
  #Time, #logout, .loggedInUser h3 h4 h5 {
    font-size: 75% !important;
  }
}