img {
    max-width: 100%;
    max-height: 100%;
}

.headerLogin {
    width: 80%;
    padding: 5px;
    margin: 0 10%;
    float: left;
    margin-bottom: 4vh;
    text-align: center;
}
.buttonLogin {
    float: left;

}

.firewall {
    height: 60vh !important;
}

.dataTest {
    height: 65vh !important;
}