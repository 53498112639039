.insetBox {
  box-shadow: inset 0 0 8px -3px #777;
}
.background_md {
  background: #42649e;
}
.background_sales {
  background: #cd8113;
}
.mdAuthContainer {
  margin-top: 1.5vh;
  border: solid 2px #dcdcdc;
}
.mdAuthHead {
  padding: 1.6vh 0;
  height: 5vh;
  color: #fff;
}
.mdAuthHead h4 {
  margin-left: 1vw;
  font-size: 1.8vh;
  margin-top: 0;
  font-weight: bold;
}
.mdAuthBody {
  height: 20vh;
  display: flex;
  flex-wrap: wrap;
  background: #f8f8f8;
}
.mdAuthBody .mdAB1 {
  font-weight: bold;
  display: flex;
  align-items: center;
  width: 25%;
  text-align: left;
  padding-left: 1vw;
}
.mdAuthBody .mdAB1 div {
  flex: 1;
}
.mdAuthBody .mdAB1 h4 {
  font-weight: bold;
  margin-bottom: 0;
  color: #444;
}
.mdAuthBody .mdAB1 h5 {
  margin-top: 0.5vh;
  color: #888;
}
.mdAuthBody .mdAB2 {
  display: flex;
  text-align: center;
  align-items: center;
  width: 25%;
}
.mdAuthBody .mdAB2 div {
  flex: 1;
}
.mdAuthBody .mdAB2 h2 {
  margin: 0;
  color: #444;
  font-weight: bold;
}
.mdAuthBody .mdAB3 {
  display: flex;
  align-items: center;
  width: 25%;
}
.mdAuthBody .mdAB3 input {
  height: 12vh;
  margin: 0 1vw;
  width: 100%;
  font-size: 5vh;
  color: #fff;
  border: 0;
  text-align: center;
}
.mdAuthBody .mdAB4 {
  display: flex;
  width: 25%;
  align-items: center;
}
.mdAuthBody .mdAB4 div {
  flex: 1;
}
.mdAuthBody hr {
  width: 100% !important;
  margin: 0 1vw !important;
}
.mdAuthBody .mdAB1,
.mdAuthBody .mdAB2,
.mdAuthBody .mdAB3,
.mdAuthBody .mdAB4 {
  height: 80%;
}
.btnMD {
  height: 5vh;
  margin: 1vh 1vw 1vh 0;
  font-weight: bold;
}
.btnMD:first-of-type {
  margin-bottom: 2vh;
}
.btnMD:hover {
  cursor: pointer;
  opacity: 0.8;
}
.btnMD .button {
  background: #1b2935;
}
.feedback {
  width: 100%;
  padding-left: 1vw;
  display: flex;
  justify-content: left;
  align-items: center;
  color: #888;
}
.feedback h5 {
  margin-top: 0.7vh;
}
.feedback h4 {
  margin-top: 0.7vh;
  margin-right: 1vw;
  font-weight: bold;
}
