.insetBox {
  box-shadow: inset 0 0 8px -3px #777;
}
.bankBalanceComponent {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.bankBalanceChart {
  height: 80%;
  border-top: solid 1px #dcdcdc;
  position: relative;
  flex: 1;
}
.bankBalanceLabelRow {
  height: 20%;
  margin: 0;
  white-space: nowrap;
  flex-wrap: nowrap;
}
.bankBalanceLabelRow span {
  font-size: 1.6vh;
}
.bankBalanceLabelRow div {
  margin: 0.75vh;
  height: 5vh;
  overflow: hidden;
}
.bankBalanceLabelRow .value {
  padding: 0.8vh 0 0 0.8vw;
  color: #fff;
}
.bankBalanceLabelRow .label {
  padding-top: 0.5vh;
  color: #888;
  text-align: left;
}
.bankBalanceLabelRow .current {
  background: #5490c0;
}
.bankBalanceLabelRow .max {
  background: #95c68a;
}
.bankBalanceLabelRow .min {
  background: #d2363b;
}
