.topHeader {
    width: 100%;
    height: 10vh;
    float: left;
}

.topHeader h1 {
    color: #5490c0;
    font-weight: bold;
}

.consoles {
    height: 6vh;
    width: 100%;
    color: #5490c0;
}

.topVideoRow {
    float: left;
    width: 100%;
    height: 20vh;
    padding: 16px;
}

.topVideo {
    width: 20%;
    float: left;
    height: 100%;
    padding: 16px;
    border: 2px solid #5490c0;
    margin-left: 16px;
    color: #5490c0;
}
.consoleHeader {
    margin-left: 16px;
    float: left;
    width: 100%;
}

.consoleVideo {
    width:18%;
    height: 20vh;
    float: left;
    padding: 16px;
    border: 2px solid #5490c0;
    margin-left: 2%;
    color: #5490c0;
}
.leftVid {
    margin-left: 16px;
}
.videoLink {
    display: inline-block;
    margin: auto;
    width: 80%;
    height: 80%;
    background-color: #5490c0;
    color: white;
    text-align: center;
}

.videoLink a {
    color: white;
    font-weight: bold;
    margin: auto;
    font-size: 40px;
}
