.insetBox {
  box-shadow: inset 0 0 8px -3px #777;
}
.background_production {
  background: #4a8a72;
}
.background_finance {
  background: #c0081f;
}
.background_market {
  background: #0084d3;
}
.background_md {
  background: #806ba9;
}
.modalTriggerButton {
  border: none;
  color: #fff;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.6vh;
  background: #1b2935;
  margin-top: 1vh;
  position: absolute;
  right: 0;
}
.modalFeedback {
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  color: #444;
}
.modalFeedback h5 {
  margin-top: 0.7vh;
  margin-right: 1vw;
}
.modal-header {
  color: #fff;
}
.modal-header.background_production {
  background: #4a8a72;
}
.modal-header.background_finance {
  background: #c0081f;
}
.modalInput {
  height: 5vh;
  margin: 0 1vw;
  width: 100%;
  font-size: 3vh;
  color: #fff;
  border: 0;
  text-align: center;
}
.modalSubmitButton {
  height: 5vh;
  font-weight: bold;
  background: #1b2935;
  color: #fff;
  width: 100%;
}
.modalSubmitButton:hover {
  cursor: pointer;
  opacity: 0.8;
}

